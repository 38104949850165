import { Alert, Card, Col, Spin } from "antd";
import React from "react";

import { Vehicle } from "../../../types";
import { useSimpleSWRFetch } from "../../../utils/rest";
import { Map } from "./Map";
import { Title } from "./Title";

export default function SummaryMap(props: { vehicle: Vehicle }) {
  const { id } = props.vehicle;
  const { error: error1, data: data1 } = useSimpleSWRFetch(
    `locations/last?filters[vehicle_id]=${id}`
  );

  const { error, data } = useSimpleSWRFetch(
    `locations?filters[vehicle_id]=${id}`
  );

  if (error || error1) {
    return (
      <Col span={15} className="pb-1 pr-1">
        <Card>
          <Alert
            type="error"
            message="Failed to load location data."
            description={error.message || "An unexpected error occurred."}
            showIcon
          />
        </Card>
      </Col>
    );
  }

  if (!data || !data1) {
    return (
      <Col span={15} className="pb-1 pr-1">
        <Card>
          <Spin tip="Cargando ubicaciones..." />
        </Card>
      </Col>
    );
  }

  if (data.length === 0 || data1.length === 0) {
    return null;
  }

  return (
    <Col span={15} className={"pb-1 pr-1"}>
      <Card title={<Title location={data1[0]} />} style={{ maxHeight: 528 }}>
        <div
          style={{ height: "58vh", width: "100%", padding: 0 }}
          className={"in-section"}
        >
          <Map entries={data || []} />
        </div>
      </Card>
    </Col>
  );
}
