import React, { useContext } from "react";
import Helmet from "react-helmet";

import { AuthContext } from "../../hooks/useAuth";
import { User } from "../../types";
import { useSimpleSWRFetch } from "../../utils/rest";
import { isTaxiFleet } from "../../utils/utils";
import DashboardComments from "./DashboardComments";
import DashboardLasLocations from "./DashboardLasLocations";
import DashboardTransactions from "./DashboardTransactions";
import DashboardVehicleIgnition from "./DashboardVehicleIgnition";
import DashboardVehicleStatus from "./DashboardVehicleStatus";
import DashboardVehiclesAssigned from "./DashboardVehiclesAssigned";

export default function Dashboard({ match, history }: any) {
  const user = useContext<User>(AuthContext);

  const { accountId } = match.params;
  const { data, isValidating } = useSimpleSWRFetch("vehicles");

  if (!isValidating && data && data.length === 0) {
    history.push(`/${accountId}/vehicles`);
  }

  const shouldShowCobranzas =
    // @ts-ignore
    isTaxiFleet(accountId) && user.roles[0]?.role_type_id === 1;
  return (
    <div style={{ minHeight: "100vh" }}>
      <Helmet>
        <title>Centro de control | Inmatics</title>
      </Helmet>

      <div className="tile-wrapper p-1">
        <div>
          <DashboardVehicleStatus accountId={accountId} />
        </div>
        <div style={{ gridRow: "1/6", gridColumn: "3/3" }}>
          <DashboardVehiclesAssigned />

          <DashboardVehicleIgnition />
        </div>

        <div style={{ gridRow: "1/6", gridColumn: "2/3" }}>
          <DashboardComments accountId={accountId} />
        </div>
        {/*Esto es solo para la cuenda de VADE SRL, cuenta de taxis que usa cobranzas*/}
        {shouldShowCobranzas && <DashboardTransactions />}
        <DashboardLasLocations />
      </div>
    </div>
  );
}
